import ItemWrong from './itemWrong';
import ItemCorrect from './itemCorrect';
import ItemLevelup from './itemLevelup';
import ItemTimeup from './itemTimeup';
import Sprite = Phaser.GameObjects.Sprite;
import {ItemManagerEvents} from '../common/events';

export default class ItemManager extends Phaser.GameObjects.Container {
    private itemAttractX = 750;
    private itemEndX = 360;
    private items: Sprite[] = [];

    constructor(scene, x, y) {
        super(scene, x, y);
    }

    onWrong(y) {
        const item = new ItemWrong(this.scene, 1260, y);
        this.addItem(item);
    }

    onTimeup(lives, y) {
        const item = new ItemTimeup(this.scene, 1260, y, lives);
        this.addItem(item);
    }

    onCorrect() {
        const item = new ItemCorrect(this.scene, 1260, this.getRandYPos());
        this.addItem(item);
    }

    onLevelup() {
        const item = new ItemLevelup(this.scene, 1260, this.getRandYPos());
        this.addItem(item);
    }

    getRandYPos() {
        // upper or lower part
        if (Phaser.Math.RND.integerInRange(0, 1) == 1) {
            return Phaser.Math.RND.integerInRange(380, 450);
        } else {
            return Phaser.Math.RND.integerInRange(500, 870);
        }
    }

    addItem(item) {
        this.items[this.items.length] = item;
        this.scene.add.existing(item);
    }

    updateItems(speed) {
        if (this.items.length > 0) {
            // move items
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];

                if (item instanceof ItemWrong || item instanceof ItemTimeup) {
                    item.rotation += .01 * speed;
                } else {
                    item.rotation += .03 * speed;
                }

                // remove dead item (change)
                if ((item.x - item.width / 2) < this.itemEndX) {
                    // type dependent feedback
                    if (item instanceof ItemWrong) {
                        this.emit(ItemManagerEvents.hitRock);
                    } else if (item instanceof ItemTimeup) {
                        this.emit(ItemManagerEvents.hitTimeUp);
                    } else if (item instanceof ItemCorrect) {
                        this.emit(ItemManagerEvents.hitBonus);
                    } else {
                        this.emit(ItemManagerEvents.hitLevelUp);
                    }
                    item.destroy();
                    this.items.splice(i, 1);

                    // last item: stop processing
                    if (this.items.length < 1) {
                        return;
                    }
                }
                item.x -= 4 + (4 * speed);
            }

            // mark first item for attraction (rocket)
            if (this.items[0].x < this.itemAttractX) {
                const distRatio = (this.items[0].x - this.itemEndX) / (this.itemAttractX - this.itemEndX);
                this.emit(ItemManagerEvents.nearTarget, distRatio, this.items[0].x, this.items[0].y);
            }

        }
    }
}
