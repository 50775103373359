/**
 * Put the game in a responsive context.
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

let timeoutHandle: NodeJS.Timeout;

// inner: 1280 * 960
const gameWidth = 1280;
// const gameHeight = 960;

// outer: 2560 * 1360
const wrapperWidth = 2560;
const wrapperHeight = 1360;

// when the page is loaded, create our game instance
window.addEventListener('resize', () => {
    clearTimeout(timeoutHandle);
    timeoutHandle = setTimeout(() => {
        const gameWrapElem = document.getElementById('gameWrapper') as HTMLTextAreaElement;
        const gameCanvas = gameWrapElem.querySelector('canvas') as HTMLCanvasElement;
        const scalePercentage = gameCanvas.offsetWidth / gameWidth;

        // rescale the background-image in proportion
        gameWrapElem.style.backgroundSize = (wrapperWidth * scalePercentage) + 'px ' + (wrapperHeight * scalePercentage) + 'px';
    }, 0);
});
