import AudioManager from '../objects/audioManager';

export default class TypeTuinScene extends Phaser.Scene {
    public audioManager!: AudioManager

    constructor(scene) {
        super(scene);
    }

    create() {
        this.audioManager = this.plugins.get('AudioManager') as AudioManager;
    }
}
