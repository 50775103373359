/**
 * Score panel of the game.
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

import SelectDifficulty from '../objects/selectDifficulty';
import ScoreDisplay from '../objects/scoreDisplay';
import Dashboard from '../objects/dashboard';
import TypeTuinScene from './type-tuin-scene';
import {GameEvents} from '../common/events';

export class ScorePanelScene extends TypeTuinScene {
    public scoreDisplay!: ScoreDisplay;
    private difficulty!: SelectDifficulty;
    private canContinueToNextState!: boolean;
    private ready!: Phaser.GameObjects.Sprite;
    private go!: Phaser.GameObjects.Sprite;
    private dashboard!: Dashboard;
    private uitleg!: Phaser.GameObjects.Sprite;
    bgOverlay!: Phaser.GameObjects.Sprite;

    constructor() {
        super({
            key: 'ScorePanelScene',
        });
    }

    init(): void {
        if (localStorage.getItem('mute') === null) {
            localStorage.setItem('mute', '1');
        }
    }

    create(): void {
        super.create();
        this.initRegistry();

        // region Add Objects
        this.bgOverlay = this.make.sprite({x: 0, y: this.game.canvas.height, key: 'sprite', frame: 'game/clouds-parallax.png', origin: {x: 0, y:1}}, true);

        this.uitleg = this.make.sprite({x: this.game.canvas.width / 2 , y: 300, key: 'sprite', frame: 'intro/uitleg.png'}, true);
        this.uitleg.setOrigin(0.5, 0);

        this.difficulty = new SelectDifficulty(this, 560, 696);
        this.difficulty.on(GameEvents.start, () => {
            this.onStartGame();
        });
        this.add.existing(this.difficulty);

        this.scoreDisplay = new ScoreDisplay(this, 190, 362);
        this.add.existing(this.scoreDisplay);

        // GLOBAL FOREGROUND OBJECTS

        this.make.sprite({ x: 0, y: this.game.canvas.height, key: 'sprite', frame: 'game/clouds-front.png', origin: {x:0, y: 1}});

        // frame
        this.make.sprite({ x: 0, y: 1, key: 'sprite', frame: 'game/frame-top.png', origin: {x:0, y: 0}});
        this.make.sprite({ x: 0, y: 200, key: 'sprite', frame: 'game/frame-left.png', origin: {x:0, y: 0}});
        this.make.sprite({ x: this.game.canvas.width, y: 200, key: 'sprite', frame: 'game/frame-right.png', origin: {x:1, y: 0}});
        this.make.sprite({ x: 0, y: this.game.canvas.height, key: 'sprite', frame: 'game/frame-bottom.png', origin: {x:0, y: 1}});

        this.dashboard = new Dashboard(this);
        this.add.existing(this.dashboard);

        // endregion

        this.ready = this.add.sprite(this.game.canvas.width / 2, this.game.canvas.height / 2, 'sprite', 'intro/txt-ready.png');
        this.ready.setOrigin(0.5, 0.5);
        this.ready.visible = false;
        this.go = this.add.sprite(this.game.canvas.width / 2, this.game.canvas.height / 2, 'sprite', 'intro/txt-go.png');
        this.go.setOrigin(0.5, 0.5);
        this.go.visible = false;
        this.canContinueToNextState = true;

        this.initialAnimation();

        this.dashboard.updateLevel();
        this.dashboard.updateScore();
        this.dashboard.updateLives();
    }

    initialAnimation() {
        this.tweens.add({targets: this.uitleg, y: {start: 1000, to: 245}, duration: 200, ease: 'Back.Out', autoStart: true, delay: 100});
        this.tweens.add({targets: this.difficulty, y: {start: 1000, to: 672}, duration: 200, ease: 'Cubic.Out', autoStart: true, delay: 300});
        this.tweens.add({targets: this.scoreDisplay, y: {start: 1000, to: 824}, duration: 200, ease: 'Cubic.Out', autoStart: true, delay: 500,
            onComplete: () => {
                this.dashboard.initialAnimation();
            },
        });
    }

    onStartGame() {
        // ALREADY FAKE LEVEL UPDATE HERE FOR AUDIO!!!
        // SO TRACK STARTS ON 'READ-GO!!!'
        this.audioManager.levelUpdate(0);

        if (!this.canContinueToNextState) { //do not allow tweens to be created multiple times simultaneously
            return;
        }

        this.scoreDisplay.hide();
        this.tweens.add({targets: this.uitleg, y: 1000, duration: 200, ease: 'Back.In', autoStart: true, delay: 100});
        this.tweens.add({targets: this.difficulty, y: 1000, duration: 300, ease: 'Cubic.Out', autoStart: true, delay: 500,
            onComplete: () => {
                this.fadeInGame();
            },
        });
        this.canContinueToNextState = false;
    }

    fadeInGame() {
        this.ready.visible = true;
        this.go.angle = -15;

        const goTween = this.tweens.add({
            targets: this.go, angle: 15, duration: 200, ease: 'Linear.In', paused: true, delay: 0, repeat: 3, yoyo: true,
            onComplete: () => {
                this.dashboard.showTopControls();
                this.go.visible = false;

                this.scene.launch('GameScene');
                this.scene.stop('MenuScene');

                // create events
                const level = this.scene.get('GameScene');
                level.events.on(GameEvents.scoreChange, () => {
                    this.dashboard.updateScore();
                });
                level.events.on(GameEvents.levelChange, () => {
                    this.dashboard.updateLevel();
                });
                level.events.on(GameEvents.livesChange, () => {
                    this.dashboard.updateLives();
                });
            },
        });

        const readyTween = this.tweens.add({
            targets: this.ready, scale: 1.5, duration: 200, ease: 'Linear.In', paused: true, delay: 0, repeat: 3, yoyo: true,
            onComplete: () => {
                this.ready.visible = false;
                this.go.visible = true;
                goTween.resume();
            },
        });

        readyTween.resume();
    }

    /**
     * Built-in global game data manager to exchange data between scenes.
     * Here we initialize our variables with a key.
     */
    private initRegistry(): void {
        this.registry.set('score', 0);
        this.registry.set('level', 1);
        this.registry.set('lives', 5);
        this.registry.set('indexWord', 5);
    }
}
