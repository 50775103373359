import {GameScene} from '../scenes/game-scene';

export default class DisplayTime extends Phaser.GameObjects.Container {
    public scene: GameScene;
    private bg: Phaser.GameObjects.Sprite;
    private timeRemaining: Phaser.GameObjects.Text;
    private baseY: number;

    constructor(scene: GameScene, x, y, time) {
        super(scene, x, y);
        this.scene = scene;

        this.bg = scene.make.sprite({x: 0, y: 0, key: 'sprite', frame: 'game/itimer.png'}, true);
        this.add(this.bg);

        const style = {font: '36px Londrina Solid', fill: '#ad0e13', align: 'center'};

        this.timeRemaining = scene.make.text({x: 22, y: -76, text: time, style: style, origin: {x: 0.5, y: 0}});
        this.add(this.timeRemaining);

        this.timeRemaining.text = '';
        this.baseY = y;
    }

    updateTime(time) {
        this.y = this.baseY;
        this.scene.tweens.add({targets: this, y: this.baseY - 2, duration: 100, ease: 'Linear', paused: false});
        this.timeRemaining.setText(time);
    }
}
