import ParticleEmitterConfig = Phaser.Types.GameObjects.Particles.ParticleEmitterConfig;

export default class ParticleCorrectWord extends Phaser.GameObjects.Group {
    private duration = 1000;
    private flowConfig: ParticleEmitterConfig;
    private particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
    private flowEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private follow: Phaser.GameObjects.GameObject;

    constructor(scene, follow) {
        super(scene);

        this.follow = follow;
        this.flowConfig = {
            frame: ['game/particles/flare_point.png'],
            delay: {min: 0, max: 100},
            lifespan: this.duration,
            frequency: this.duration + 100,
            scale: {start: 0.5, end: 1, ease: 'Expo.easeOut'},
            alpha: {start: 0.8, end: 0, ease: 'Expo.easeIn'},
            quantity: 20,
            speed: [50, 100],
            rotate: {start: -90, end: 90},
            angle: {min: -180, max: 180},
            gravityY: -50,
            on: false,

            blendMode: 'ADD',
        } as ParticleEmitterConfig;

        this.particles = this.scene.add.particles('sprite').setDepth(10);

        this.flowEmitter = this.particles.createEmitter(this.flowConfig);
    }

    fire() {
        this.flowEmitter.startFollow(this.follow, 270).start();
        setTimeout(() => {
            this.flowEmitter.on = false;
        }, this.duration);
    }
}
