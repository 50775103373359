import Animation = Phaser.Types.Animations.Animation;

export default class ItemLevelup extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y) {
        super(scene, x, y, 'sprite', 'game/items/levelup/00.png');
        this.setOrigin(0.5);

        scene.anims.create({
            key: 'lvl_blink',
            frames: scene.anims.generateFrameNames('sprite', {
                prefix: 'game/items/levelup/', start: 1, end: 2, suffix: '.png', zeroPad: 2,
            }),
            repeat: -1,
            frameRate: 15,
        } as Animation);

        this.play('lvl_blink', true);
    }
}
