import POINTER_OVER = Phaser.Input.Events.POINTER_OVER;
import POINTER_OUT = Phaser.Input.Events.POINTER_OUT;
import POINTER_DOWN = Phaser.Input.Events.POINTER_DOWN;
import POINTER_UP = Phaser.Input.Events.POINTER_UP;

export interface ButtonConfig {
    overFramePosition?: { x: number; y: number };
    outFramePosition?: { x: number; y: number };
    downFramePosition?: { x: number; y: number };
    hitArea?: {x: number; y: number; width: number; height: number};
}

export class Button extends Phaser.GameObjects.Container {
    private readonly outFrame: Phaser.GameObjects.Sprite;
    private readonly overFrame: Phaser.GameObjects.Sprite;
    private readonly downFrame: Phaser.GameObjects.Sprite;

    constructor(scene, x: number, y: number, fn: Function, sprite: string, overFrame: string, outFrame: string, downFrame: string, config?: ButtonConfig) {
        super(scene, x, y);

        this.outFrame = scene.make.sprite({
            x: config?.outFramePosition?.x ?? 0,
            y: config?.outFramePosition?.y ?? 0,
            key: 'sprite', frame: outFrame, alpha: 1}, true);
        this.overFrame = scene.make.sprite({
            x: config?.overFramePosition?.x ?? 0,
            y: config?.overFramePosition?.y ?? 0,
            key: 'sprite', frame: overFrame, alpha: 0}, true);
        this.downFrame = scene.make.sprite({
            x: config?.downFramePosition?.x ?? 0,
            y: config?.downFramePosition?.y ?? 0,
            key: 'sprite', frame: downFrame, alpha: 0} , true);
        this.add([this.outFrame, this.overFrame, this.downFrame]);

        this.width = this.outFrame.width;
        this.height = this.outFrame.height;

        this.setInteractive({ useHandCursor: true })
            .on(POINTER_OVER, () => this.enterButtonHoverState() )
            .on(POINTER_OUT, () => this.enterButtonOutState() )
            .on(POINTER_DOWN, () => this.enterButtonDownState() )
            .on(POINTER_UP, () => {
                this.enterButtonHoverState();
                fn();
            });
        if (config && config?.hitArea) {
            this.input.hitArea.setTo(config.hitArea.x, config.hitArea.y, config.hitArea.width, config.hitArea.height);
        }
    }

    enterButtonHoverState() {
        this.outFrame.alpha = 0;
        this.downFrame.alpha = 0;
        this.overFrame.alpha = 1;
    }

    enterButtonOutState() {
        this.outFrame.alpha = 1;
        this.downFrame.alpha = 0;
        this.overFrame.alpha = 0;
    }

    enterButtonDownState() {
        this.outFrame.alpha = 0;
        this.downFrame.alpha = 1;
        this.overFrame.alpha = 0;
    }
}
