import ParticleEmitterConfig = Phaser.Types.GameObjects.Particles.ParticleEmitterConfig;

export default class ParticleExplode extends Phaser.GameObjects.Group {

    //initialization code in the constructor
    private duration = 2100;
    private shrapnelConfig: ParticleEmitterConfig;
    private smokeConfig: ParticleEmitterConfig;
    private particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
    private shrapnelEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private smokeEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private follow: Phaser.GameObjects.GameObject;

    constructor(scene, follow) {
        super(scene);

        this.follow = follow;

        this.shrapnelConfig = {
            frame: 'game/particles/scrap.png',
            delay: {min: 0, max: 200},
            lifespan: 1300,
            frequency: this.duration + 100, //this prevents running more than once
            scale: {start: 0.6, end: 0.6, ease: 'Expo.easeOut'},
            alpha: {start: 1, end: 0},
            quantity: 20,
            speed: {min: 50, max: 200},
            rotate: {min: -180, max: 180},
            angle: {min: -180, max: 0},
            gravityY: 200,
            on: false,

            // blendMode: 'SCREEN',
        } as ParticleEmitterConfig;

        this.smokeConfig = {
            frame: 'game/particles/smoke-puff-white.png',
            delay: [0, 100],
            lifespan: {min: 1200, max: 2000},
            frequency: this.duration + 100, //this prevents running more than once
            // scale: {start: 0.3, end: 0.8, ease: 'Expo.easeOut'},
            scale: (p, k, t) => {
                return Phaser.Math.Clamp(0.3 + t, 0, 1);
            },
            // alpha: {start: 0.1, end: 0.6, ease: 'Expo.easeOut'},
            alpha: (p, k, t) => {
                return 1 - Math.abs(t);
            }, // {start: 0.8, end: 0, ease: 'Expo.easeIn'},
            quantity: 2,
            speed: {min: 50, max: 175},
            rotate: {min: -2, max: 2},
            angle: {min: -170, max: -110},
            gravityY: -50,
            on: false,

            blendMode: 'SCREEN',
        } as ParticleEmitterConfig;

        this.particles = this.scene.add.particles('sprite').setDepth(10);
        // this.add(this.particles);

        this.shrapnelEmitter = this.particles.createEmitter(this.shrapnelConfig);
        this.smokeEmitter = this.particles.createEmitter(this.smokeConfig);
    }

    fire(level) {
        this.smokeEmitter.setSpeed({min: 50+ level * 50, max: 75 + level * 50});

        this.shrapnelEmitter.startFollow(this.follow, 270).start();
        this.smokeEmitter.startFollow(this.follow, 270).start();

        setTimeout(() => {
            this.shrapnelEmitter.on = false;//.stop();
            this.smokeEmitter.on = false;//.stop();
        }, this.duration);
    }
}
