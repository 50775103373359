/**
 * Contains the enums used in the project
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

export enum EnumDifficulty {
    easy,
    medium,
    hard,
}
