/**
 * Game scene of the game.
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

import Word from '../objects/word';
import {EnumDifficulty} from '../common/enums';
import {GameDefinitions} from '../types/gameDefinitions';
import GameComplete from '../objects/gameComplete';
import TypeTuinScene from './type-tuin-scene';
import GameOver from '../objects/gameOver';
import TextStyle = Phaser.Types.GameObjects.Text.TextStyle;
import LevelUp from '../objects/levelUp';
import DisplayTime from '../objects/displayTime';
import ItemManager from '../objects/itemManager';
import RocketFoxy from '../objects/rocketFoxy';
import SHIFT = Phaser.Input.Keyboard.KeyCodes.SHIFT;
import CTRL = Phaser.Input.Keyboard.KeyCodes.CTRL;
import ENTER = Phaser.Input.Keyboard.KeyCodes.ENTER;
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;
import ANY_KEY_DOWN = Phaser.Input.Keyboard.Events.ANY_KEY_DOWN;
import {ScorePanelScene} from './score-panel-scene';
import ANY_KEY_UP = Phaser.Input.Keyboard.Events.ANY_KEY_UP;
import {GameEvents, ItemManagerEvents} from '../common/events';

export class GameScene extends TypeTuinScene {

    private gameDefinition: GameDefinitions | undefined;
    private currentLevel = 1;
    public difficulty: EnumDifficulty = EnumDifficulty.easy;
    private currLetter = 0;
    private currentText!: Word;
    private textLayer!: Phaser.GameObjects.Container; // todo : of container?
    private texts: string[] = [];
    private livesRemaining = 0;
    private scoreIndexDifficulty = [1, 1.25, 1.5];
    private scoreIndexLevels = [1, 1.1, 1.2, 1.3, 1.4, 1.5];
    private levelUp!: LevelUp;
    private currentLevelTextsCompleted = 0;
    private gameOver = false;
    private livesMax = 0;
    private speedIndex: number[] = [1, 2, 3, 4, 5];
    private speed = this.speedIndex[0];
    private displayTime!: DisplayTime;
    private itemManager!: ItemManager;
    private rocketFoxy!: RocketFoxy;
    private sentenceTimerEvent!: Phaser.Time.TimerEvent;
    private bg!: Phaser.GameObjects.TileSprite;
    private bgOverlay!: Phaser.GameObjects.TileSprite;
    private activeKeys = new Set();

    constructor() {
        super({key: 'GameScene'});
    }

    // region Phaser methods

    preload(): void {
        this.setCurrentLevel(this.registry.get('level') ?? this.currentLevel);
        this.gameDefinition = this.cache.json.get('gameDefinition') as GameDefinitions;
        this.difficulty = parseInt(localStorage.getItem('difficulty') ?? '0');
    }

    create(): void {
        super.create();

        // region Data
        this.livesRemaining = this.gameDefinition?.livesInit ?? 0;
        this.livesMax = this.gameDefinition?.livesMax ?? 0;

        // endregion

        // region Visuals General

        this.bg = this.make.tileSprite({x: 0, y: 1, key: 'sprite', frame: 'game/parallax-bg.png', origin: {x: 0, y: 0}, width: this.game.canvas.width, height: 960}, true);
        this.bgOverlay = this.make.tileSprite({x: 0, y: this.game.canvas.height, key: 'sprite', frame: 'game/clouds-parallax.png', origin: {x: 0, y: 1}, width: this.game.canvas.width, height: 251}, true);
        // this is the moving clouds. they become visible on game start.
        (this.scene.get('ScorePanelScene') as ScorePanelScene).bgOverlay.alpha = 0;

        this.itemManager = new ItemManager(this, 0, 0);
        this.add.existing(this.itemManager);
        this.itemManager.on(ItemManagerEvents.nearTarget, (ratio, x, y) => {
            this.rocketFoxy.stopToOrigin();
            const dir = y - this.rocketFoxy.y;
            this.rocketFoxy.y += ((dir) * ratio * .1);
            this.rocketFoxy.rotation = dir / 800;
        });
        this.itemManager.on(ItemManagerEvents.hitRock, () => {
            this.rocketFoxy.rotation = 0;
            this.rocketFoxy.hitRock(this.speed, this.livesRemaining);
            this.decreaseLives();
        });
        this.itemManager.on(ItemManagerEvents.hitTimeUp, () => {
            this.rocketFoxy.rotation = 0;
            this.rocketFoxy.hitTimeup(this.speed, this.livesRemaining);
            this.decreaseLives();
            this.destroyWord(false);
        });
        this.itemManager.on(ItemManagerEvents.hitBonus, () => {
            this.rocketFoxy.rotation = 0;
            this.rocketFoxy.hitBonus(this.speed, this.livesRemaining);
            this.destroyWord();
            // this is already done at start of correctword, cause this could save the player when
            // a rock is also thrown just before.
            // this.increaseLives();
        });
        this.itemManager.on(ItemManagerEvents.hitLevelUp, () => {
            this.rocketFoxy.rotation = 0;
            this.rocketFoxy.hitLevelup(this.speed, this.livesRemaining);
            this.destroyWord();
        });

        this.rocketFoxy = new RocketFoxy(this, 110, this.game.canvas.height / 2 + 50);
        this.rocketFoxy.x = -300;
        this.add.existing(this.rocketFoxy);

        this.tweens.add({targets: this.rocketFoxy, x: 110, duration: 1000, ease: 'Back.Out'});

        this.textLayer = new Phaser.GameObjects.Container(this, this.game.canvas.width / 2, this.game.canvas.height - 50).setDepth(100);
        this.add.existing(this.textLayer);

        this.levelUp = new LevelUp(this);
        this.levelUp.y = (this.sys.canvas.height / 2) - 50;
        this.levelUp.x = this.sys.canvas.width / 2;
        this.add.existing(this.levelUp);

        this.displayTime = new DisplayTime(this, this.game.canvas.width - 150, this.game.canvas.height - 150, 0)
            .setDepth(1);
        this.add.existing(this.displayTime);

        // endregion

        // region Init

        this.scene.bringToTop('ScorePanelScene');

        this.texts = this.gameDefinition?.texts[this.difficulty] || [];

        // todo 4000
        this.time.addEvent({
            delay: 500, callback: () => {
                this.initGame();
            },
        });

        // endregion
    }

    update() {
        this.bg.tilePositionX += this.speed;
        this.bgOverlay.tilePositionX += 1.5 * this.speed;
        this.itemManager.updateItems(this.speed);
    }

    // endregion

    // region custom methods

    initGame() {
        this.makeNewText();
    }

    secondsPerText() {
        return this.gameDefinition?.levels[this.currentLevel-1].secondsPerText ?? 0;
    }

    textsToComplete() {
        return this.gameDefinition?.levels[this.currentLevel-1].textsToComplete ?? 0;
    }

    updateSpeed() {
        this.speed = this.speedIndex[this.currentLevel-1];
    }

    nextLevel() {
        if (this.currentLevel === this.gameDefinition?.levels?.length) {
            this.completeGame();
        } else {
            this.setCurrentLevel(this.currentLevel + 1);
            this.updateSpeed();
            this.audioManager.levelUpdate(this.currentLevel);
            this.levelUp?.show();
            this.currentLevelTextsCompleted = 0;
        }
    }

    makeNewText() {
        const index = Math.floor(Math.random() * this.texts.length);
        const word = this.texts[index];
        this.texts.splice(index, 1);
        this.currLetter = 0;
        this.currentText = new Word(this, 0, 0, word);
        this.currentText.y = 700;

        // Capture all key presses
        this.input.keyboard.on(ANY_KEY_DOWN, (char) => {
            if (!this.activeKeys.has(char.code)) {
                this.activeKeys.add(char.code);
                this.keyPress(char);
            }
        });
        this.input.keyboard.on(ANY_KEY_UP, (char) => {
            this.activeKeys.delete(char.code);
        });
        const w = this.add.existing(this.currentText);
        this.textLayer?.add(w);

        const fadeInWord = this.tweens.add({
            targets: this.currentText,
            y: -70,
            duration: 1000,
            ease: 'Cubic.Out',
            delay: 500,
        });
        fadeInWord.on(TWEEN_COMPLETE, () => {
            this.makeNewTextComplete();
        });
    }

    makeNewTextComplete() {
        this.time.removeAllEvents();
        this.time.paused = false;
        this.time.addEvent({
            delay: 1000,
            loop: true,
            callback: () => {
                this.sentenceTimerUpdateDisplay();
            },
        });
        this.sentenceTimerEvent = this.time.addEvent({
            delay: this.secondsPerText() * 1000,
            loop: false,
            timeScale: 1,
            callback: () => {
                this.sentenceTimerComplete();
            },
        });
    }

    sentenceTimerPause() {
        this.time.paused = true;
        // this.sentenceTimerEvent.paused = true;
    }

    sentenceTimerComplete() {
        this.audioManager.playFX('wronglong', .6);
        this.rocketFoxy.fearmore(this.livesRemaining);
        this.endkeyboardCallbacks();
        this.currentText.onDisable();
        this.time.removeAllEvents();
        this.itemManager.onTimeup(this.livesRemaining, this.rocketFoxy.y);
    }

    sentenceTimerUpdateDisplay() {
        const remaining = Phaser.Math.RoundTo(this.secondsPerText() - this.sentenceTimerEvent.getElapsedSeconds());
        this.displayTime.updateTime(remaining);
    }

    keyPress(char): void {
        this.audioManager.playFX('tick', .8);

        if (char.keyCode === SHIFT || char.keyCode === CTRL || char.keyCode === ENTER) {
            return;
        }

        if (char.key === this.currentText.text[this.currLetter]) {
            this.currLetter++;
            this.currentText.updateColor(this.currLetter);
            if (this.currLetter == this.currentText.text.length) {
                this.textCorrect();
            }
        } else {
            this.wrongLetter();
        }
    }

    incrementScore() {
        // todo mvb check if score calculation is the same as in the old game
        // 100-0 punten afhanklijk van de voortgang, level en moeilijkheidsgraad
        const score = Math.round((this.currentText?.scoreValue ?? 0) * this.scoreIndexDifficulty[this.difficulty] * this.scoreIndexLevels[this.currentLevel-1]);
        this.registry.set('score', this.registry.get('score') + score);
        this.events.emit(GameEvents.scoreChange);
    }

    addBonusScore() {
        const bonusScore = this.livesRemaining * 25;

        this.registry.set('score', this.registry.get('score') + bonusScore);
        this.events.emit(GameEvents.scoreChange);

        return bonusScore;
    }

    decreaseLives() {
        if (this.livesRemaining > 0) {
            this.livesRemaining--;

            this.registry.set('lives', this.livesRemaining);
            this.events.emit(GameEvents.livesChange);
        }
        if (this.livesRemaining == 0 && !this.gameOver) {
            this.endGame();
            this.gameOver = true;
        }
    }

    increaseLives() {
        if (this.livesRemaining < this.livesMax) {
            this.livesRemaining++;

            this.registry.set('lives', this.livesRemaining);
            this.events.emit(GameEvents.livesChange);
        }
    }

    textCorrect() {
        // score
        this.endkeyboardCallbacks();
        this.incrementScore();
        this.sentenceTimerPause();
        this.rocketFoxy.blink(this.livesRemaining);

        this.currentLevelTextsCompleted++;
        this.currentText?.onCorrect();

        this.increaseLives();

        if (this.currentLevelTextsCompleted == this.textsToComplete()) {
            this.nextLevel();
            this.itemManager.onLevelup();
            this.audioManager.playFX('grow', .6);
        } else {
            this.itemManager.onCorrect();
            this.audioManager.playFX('succes', .6);
        }
    }

    wrongLetter() {
        this.audioManager.playFX('wrongshort');
        this.rocketFoxy.fear(this.livesRemaining);
        this.itemManager.onWrong(this.rocketFoxy.y);
        this.currentText.onWrong();
    }

    destroyWord(correct = true) {
        this.endkeyboardCallbacks();

        let fadeOutWord;
        if (correct) {
            fadeOutWord = this.tweens.add({
                targets: this.currentText,
                alpha: 0,
                duration: 500,
                ease: 'Linear',
                delay: 500,
            });
        } else {
            fadeOutWord = this.tweens.add({
                targets: this.currentText,
                y: 150,
                duration: 500,
                ease: 'Back.Out',
                delay: 500,
            });
        }
        fadeOutWord.on(TWEEN_COMPLETE, () => {
            this.destroyWordComplete();
        });
    }

    destroyWordComplete() {
        this.currentText.destroy();
        if ((this.currentLevel) <= (this.gameDefinition?.levels.length ?? 0) && !this.gameOver) {
            this.makeNewText();
        }
    }

    setCurrentLevel(level: number): void {
        this.currentLevel = level;
        this.registry.set('level', this.currentLevel);
        this.events.emit(GameEvents.levelChange);
    }

    endGame() {
        this.sentenceTimerPause();

        const scoreDisplay = (this.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        scoreDisplay.setTotalScore();
        scoreDisplay.displayFinalScore();

        this.audioManager.gameOver();
        const gameover = new GameOver(this, this.game.canvas.width / 2 + 160, this.game.canvas.height / 2 - 80);
        this.add.existing(gameover);

        this.rocketFoxy.blink(this.livesRemaining, 0);
        this.rocketFoxy.wiggle();
        this.endGameRemove();
    }

    completeGame() {
        this.sentenceTimerPause();
        const bonusScore = this.addBonusScore();
        const scoreDisplay = (this.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        scoreDisplay.setTotalScore();

        const style = {
            font: '28px Londrina Solid', fill: '#ffffff', align: 'center',
            stroke: '#e79609', strokeThickness: 6, lineSpacing: -20,
        } as TextStyle;

        const bonusScoreTxt = this.make.text({x: 800, y: 557, text: bonusScore + ' bonuspunten!', style, origin: 0.5, scale: 0}, true);
        this.tweens.add({
            targets: bonusScoreTxt,
            scaleX: 1,
            scaleY: 1,
            duration: 500,
            ease: 'Bounce.Out',
            delay: 2500,
        });

        scoreDisplay.displayFinalScore();

        const gamecomplete = new GameComplete(this, this.game.canvas.width / 2 + 50, this.game.canvas.height / 2 - 60);
        this.add.existing(gamecomplete);
        this.endGameRemove();

        this.audioManager.gameComplete();
    }

    endGameRemove() {
        this.currentText?.destroy();
        this.endkeyboardCallbacks();
    }

    endkeyboardCallbacks() {
        this.input.keyboard.off('keydown');
    }

    // endregion
}
