// import Animation = Phaser.Types.Animations.Animation;

export default class ItemCorrect extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y) {
        super(scene, x, y, 'sprite');
        this.setOrigin(0.5);

        // take a random color
        const color = ['rood', 'geel', 'roze'][Phaser.Math.Between(0, 2)];
        this.setTexture('sprite', `game/items/diamant-${color}/diamant_000.png`);
    }
}
