import TextStyle = Phaser.GameObjects.TextStyle;
import Tween = Phaser.Tweens.Tween;
import {GameScene} from '../scenes/game-scene';
import {WordStatusEvents} from '../common/events';

export default class Word extends Phaser.GameObjects.Container {
    public scoreValue = 0;
    public text: string;
    public scene: GameScene;

    private moveWord: Tween | undefined;
    private remainingMS = 0;

    private readonly scoreValueBase: number;
    private readonly correctWord: Phaser.GameObjects.Text;
    private readonly characterCrop: number[];
    private readonly word: Phaser.GameObjects.Text;

    constructor(scene: GameScene, x = 0, y = 0, word: string) {
        super(scene, x, y);
        this.scene = scene;
        let fontSize = '50px';
        if (word.length > 65) {
            fontSize = '36px';
        } else if (word.length > 60) {
            fontSize = '38px';
        } else if (word.length > 55) {
            fontSize = '41px';
        } else if (word.length > 50) {
            fontSize = '44px';
        } else if (word.length > 40) {
            fontSize = '46px';
        }
        const style = {
            fontFamily: 'Londrina Solid',
            fontSize: fontSize,
            color: '#1d302b',
            align: 'center',
            stroke: '#ffffff',
            strokeThickness: 3,
        } as TextStyle;

        this.word = scene.add.text(0, 0, word, style)
            .setOrigin(0.5, 0.5);
        this.add(this.word);
        this.correctWord = scene.add.text(0, 0, word, Object.assign({}, style, {color: '#2bb62e'}))
            .setOrigin(0.5, 0.5);
        this.correctWord.setCrop(0, 0, 0, this.correctWord.height);
        this.add(this.correctWord);

        this.characterCrop = this.getCharacterCrop(word, style);
        this.text = this.correctWord.text;

        // set as props
        this.scoreValueBase = word.length;
        this.scoreValue = this.scoreValueBase;
    }

    onDisable(){
        this.word.setColor('#da6006');
        this.correctWord.alpha = 0;
        this.rotation = 0;
        this.scene?.tweens.add({ targets: this, rotation: { from: .05, to: 0}, scaleX: { from: 1.1, to: 1}, scaleY: {from: 1.1, to: 1}, duration: 200, ease: 'Linear', delay: 100});
    }

    onWrong(){
        this.rotation = 0;
        this.scene.tweens.add({ targets: this,  rotation: { from: .05, to: 0}, duration: 200, ease: 'Linear', delay: 100});
    }

    onCorrect(): void {
        if (this.moveWord) {
            this.scene.tweens.remove(this.moveWord);
        }
        this.scene.tweens.timeline({
            onComplete: () => {
                this.emit(WordStatusEvents.change, this, 'correctComplete');
            },
        })
            .add({targets: this, scaleX: 1.1, scaleY: 1.1, duration: 200, ease: 'Elastic.In', offset: 0})
            .play();
    }

    /**
     * Unveils the correct part of the word by setting a cropping to the character position.
     * Phaser 2 had the possibility to set the color of single characters in a text object, but Phaser 3
     * does not have this option.
     *
     * @param pos
     */
    updateColor(pos: number): void {
        this.correctWord.setCrop(0, 0, this.characterCrop[pos - 1], this.correctWord.height);
    }

    /**
     * In this helper function the width of each character of the word is calculated
     * and placed in the characterCrop array.
     * The array contains cumulative values, so it can be easily used to set the cropping
     * in the updateColor function.
     *
     * @param word
     * @param style
     */
    private getCharacterCrop(word: string, style: TextStyle): number[] {
        const chWidths: number[] = [];
        const txt = this.scene.make.text({
            style: style,
        }, false)

        let width = 0;
        let txtPart = '';
        for (const character of word) {
            txtPart += character;
            txt.setText(txtPart);
            txt.updateText();
            width = txt.width - txt.style.baselineX * 2
            chWidths.push(width);
        }
        return chWidths;
    }

    destroy() {
        this.scene?.tweens.add({
            targets: this.scale, x: 0, y: 0, duration: 300, ease: 'Elastic.In', paused: false,
            onComplete: () => {
                this.emit(WordStatusEvents.change, this, 'destroy', this.remainingMS);

                this.correctWord.destroy(true);

                super.destroy();
            },
        });
    }
}
