import {Button} from './button';
import {ScorePanelScene} from '../scenes/score-panel-scene';
import {GameEvents} from '../common/events';

export default class SelectDifficulty extends Phaser.GameObjects.Container {


    private buttonMin: Button;
    private buttonPlus: Button;
    private buttonStart: Button;
    private wijzer: Phaser.GameObjects.Sprite;
    private diffText: Phaser.GameObjects.Text;
    public scene!: ScorePanelScene;

    constructor(scene: ScorePanelScene, x, y) {
        super(scene, x, y);

        this.add(scene.add.sprite(0, 0, 'sprite', 'intro/meter.png').setOrigin(0, 0));

        this.buttonMin = new Button(scene, 30, 72, () => {
            this.onMinus();
        }, 'sprite', 'intro/btn-diff-minus-hover.png', 'intro/btn-diff-minus.png', 'intro/btn-diff-minus-hover.png', {
            hitArea: {x: -30, y: -30, width: 60, height: 60},
        });
        this.add(scene.add.existing(this.buttonMin));

        this.buttonPlus = new Button(scene, 164, 72, () => {
            this.onPlus();
        }, 'sprite', 'intro/btn-diff-plus-hover.png', 'intro/btn-diff-plus.png', 'intro/btn-diff-plus-hover.png', {
            hitArea: {x: -30, y: -30, width: 60, height: 60},
        });
        this.add(scene.add.existing(this.buttonPlus));

        this.wijzer = this.scene.make.sprite({x: 98, y: 68, key: 'sprite', frame: 'display/difficulty-wijzer.png'}, true);
        this.wijzer.setOrigin(.5, .85);
        this.add(this.wijzer);

        this.diffText = scene.make.text({x: 96, y: 168, text: '', style: {fontFamily: 'Londrina Solid', fontSize: '22px', color: '#9a847f', align: 'center'}}, true).setOrigin(0.5, 0.5);
        this.add(this.diffText);

        this.buttonStart = new Button(scene, 356, 150, () => {
            this.onStart();
        }, 'sprite', 'intro/btn-start-hover.png', 'intro/btn-start.png', 'intro/btn-start-hover.png', {
            hitArea: {x: -40, y: -25, width: 200, height: 85},
        });
        this.add(scene.add.existing(this.buttonStart));

        if (localStorage.getItem('difficulty') === null) {
            localStorage.setItem('difficulty', '0');
        }

        this.onUpdate();
    }

    //Load operations (uses Loader), method called first
    onPlus() {
        let diff = SelectDifficulty.getDifficultyFromStorage();
        if (diff < 2) {
            diff++;
            // this.scene.AudioM
            localStorage.setItem('difficulty', diff.toString());
            this.scene.audioManager.playFX('meter');
            this.onUpdate();
        } else {
            this.scene.audioManager.playFX('meter', .4);
            this.onUpdateFakePlus();
        }
        this.onUpdate();
    }

    onMinus() {
        let diff = SelectDifficulty.getDifficultyFromStorage();
        if (diff > 0) {
            diff--;
            localStorage.setItem('difficulty', diff.toString());
            this.scene.audioManager.playFX('meter');
            this.onUpdate();
        } else {
            this.scene.audioManager.playFX('meter', .4);
            this.onUpdateFakeMinus();
        }
    }

    onStart() {
        this.emit(GameEvents.start);
    }

    onUpdateFakeMinus() {
        this.scene.tweens.add({targets: this.wijzer, rotation: { from: this.wijzer.rotation-.1, to: this.wijzer.rotation }, duration: 300, ease: 'Back.Out', autoStart: true});
    }

    onUpdateFakePlus() {
        this.scene.tweens.add({targets: this.wijzer, rotation: { from: this.wijzer.rotation+.1, to: this.wijzer.rotation }, duration: 300, ease: 'Back.Out', autoStart: true});
    }

    onUpdate() {
        let angle = this.wijzer.angle;
        const diff = SelectDifficulty.getDifficultyFromStorage();

        if (diff === 0) {
            angle = -100;
            this.diffText.text = 'makkelijk';
        } else if (diff == 1) {
            angle = 0;
            this.diffText.text = 'normaal';
        } else if (diff == 2) {
            angle = 100;
            this.diffText.text = 'moeilijk';
        }
        this.scene.tweens.add({targets: this.wijzer, angle: angle, duration: 300, ease: 'Back.Out', autoStart: true});
    }

    private static getDifficultyFromStorage() {
        return parseInt(localStorage.getItem('difficulty') ?? '0');
    }
}
