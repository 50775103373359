import Sprite = Phaser.GameObjects.Sprite;
import TIMELINE_COMPLETE = Phaser.Tweens.Events.TIMELINE_COMPLETE;
import {LevelUpEvents} from '../common/events';

export default class LevelUp extends Phaser.GameObjects.Container {
    private bonusLife: Sprite;
    private levelup: Sprite;

    constructor(scene, x?, y?) {
        super(scene, x, y);

        this.bonusLife = scene.add.sprite(0, 50, 'sprite', 'game/items/bonus-life.png').setOrigin(0.5);
        this.add(this.bonusLife);

        this.levelup = scene.add.sprite(0, 50, 'sprite', 'game/items/text-levelup.png').setOrigin(0.5);
        this.add(this.levelup);

        this.alpha = 0;
    }

    show(): void {
        this.alpha = 1;

        this.scale = 0.1;
        this.bonusLife.scale = 0;
        const tl = this.scene.tweens.createTimeline();
        tl.add({targets: this, scale: 1, duration: 500, ease: 'Bounce.Out', autoStart: true, delay: 0});
        tl.add({targets: this.bonusLife, scale: 1, duration: 500, ease: 'Elastic.Out', autoStart: true, delay: 500});
        tl.add({targets: this, alpha: 0, duration: 200, ease: 'Quart.easeOut', autoStart: true, delay: 1800});
        tl.on(TIMELINE_COMPLETE, () => {
            this.showComplete();
        });

        tl.play();
    }

    showComplete(): void {
        this.scene.time.addEvent({
            delay: 500, callback: () => {
                this.emit(LevelUpEvents.complete);
            },
        });
    }
}
