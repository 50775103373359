import SoundMarker = Phaser.Types.Sound.SoundMarker;
import SoundConfig = Phaser.Types.Sound.SoundConfig;
import DECODED_ALL = Phaser.Sound.Events.DECODED_ALL;
import STOP = Phaser.Sound.Events.STOP;

export default class AudioManager extends Phaser.Plugins.BasePlugin {

    //initialization code in the constructor
    private sfx;
    private loopIntro!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private loop1!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private loop2!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private loop3!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private loop4!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private loop5!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private initEnd!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private sounds!: (Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound)[];
    private currentLoop!: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    // private previousLoop?: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private currentLevel!: number;
    private loopMarker = {
        name: 'loop',
        start: 0,
        // duration: 7.68,
        config: {
            loop: true,
        },
    } as SoundMarker;

    constructor(pluginManager) {
        super(pluginManager);
    }

    init() {
        this.sfx = this.game.cache.json.get('sfx').spritemap;
        this.sfx.allowMultiple = true;

        this.loopIntro = this.game.sound.add('loop-intro');
        this.loop1 = this.game.sound.add('loop-1');
        this.loop2 = this.game.sound.add('loop-2');
        this.loop3 = this.game.sound.add('loop-3');
        this.loop4 = this.game.sound.add('loop-4');
        this.loop5 = this.game.sound.add('loop-5');
        this.initEnd = this.game.sound.add('end');
        this.sounds = [  this.loopIntro,this.loop1,this.loop2,this.loop3,this.loop4,this.loop5,this.initEnd];

        this.game.sound.on(DECODED_ALL, () => {this.playMusic();});

        this.currentLevel = 0;
    }

    //Load operations (uses Loader), method called first
    levelUpdate(level) {
        this.currentLevel = level;
        if (level == 0) {
            this.playFX('nextlevel', .3);
            this.levelUpdateNext();
        } else {
            this.playFX('nextlevel');
            this.levelUpdateNext();
        }
    }


    levelUpdateNext() {
        this.stopCurrent();
        if (this.currentLevel == 0) {
            this.currentLoop = this.loop1;
        } else if (this.currentLevel == 1) {
            this.currentLoop = this.loop2;
        } else if (this.currentLevel == 2) {
            this.currentLoop = this.loop3;
        } else if (this.currentLevel == 3) {
            this.currentLoop = this.loop4;
        } else if (this.currentLevel == 4) {
            this.currentLoop = this.loop5;
        }

        this.currentLoop.play('',{ volume: 0, loop: true});
        this.game.scene.getScene('ScorePanelScene').tweens.add({ targets: this.currentLoop, volume: 1, duration: 500 });
    }

    gameOver() {
        this.playFX('gameover');
        this.gameComplete();
    }

    gameComplete() {
        this.stopCurrent();
        const complete = this.initEnd;
        complete.play();
        complete.on(STOP, () => {
            this.gameCompleteLoop();
        });
    }

    gameCompleteLoop() {
        this.stopCurrent();
        this.currentLoop = this.loopIntro;
        this.currentLoop.addMarker(this.loopMarker);
        this.currentLoop.play(undefined, {loop: true} as SoundConfig);
    }

    stopCurrent() {
        if (this.currentLoop !== undefined) {
            this.currentLoop.removeMarker(this.loopMarker.name);
            this.currentLoop.stop();
        }
    }

    playMusic() {
        const activeScenes = this.game.scene.getScenes();
        const gameActive = activeScenes.find((scene) => {
            return (scene.scene.key === 'GameScene');// !== undefined;
        })
        const menuActive = activeScenes.find((scene) => {
            return (scene.scene.key === 'MenuScene');// !== undefined;
        })

        if (gameActive !== undefined) {
            this.currentLoop = this.loop1;
            this.currentLoop.addMarker(this.loopMarker);
            this.currentLoop.play(undefined, {loop: true, volume: 0} as SoundConfig);
            gameActive.tweens.add({ targets: this.currentLoop, volume: 1, duration: 2000 });
            // this.currentLoop.fadeIn(2000, true);
        } else if (menuActive !== undefined) {
            this.currentLoop = this.loopIntro;
            this.currentLoop.addMarker(this.loopMarker);
            this.currentLoop.play(undefined, {loop: true, volume: 0} as SoundConfig);
            menuActive.tweens.add({ targets: this.currentLoop, volume: .5, duration: 1000 });
            // this.currentLoop.fadeTo(1000, .5);
        }
    }

    playFX(sfx, volume?: number, delay?: number) {
        this.game.sound.playAudioSprite('sfx', sfx, { delay: delay, volume: volume} as SoundConfig)
    }
}
