import ParticleEmitterConfig = Phaser.Types.GameObjects.Particles.ParticleEmitterConfig;

export default class ParticleRocket extends Phaser.GameObjects.Group {

    //initialization code in the constructor
    private flameConfig: ParticleEmitterConfig;
    private glowConfig: ParticleEmitterConfig;
    private particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
    private flameEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private glowEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private follow: Phaser.GameObjects.GameObject;

    constructor(scene, follow) {
        super(scene);

        this.setDepth(10);
        this.follow = follow;

        this.glowConfig = {
            frame: ['game/particles/white.png','game/particles/yellow.png','game/particles/orange.png','game/particles/red.png'],
            delay: {min: 0, max: 200},
            lifespan: 750,
            frequency: 250,
            scale: {start: 0.1, end: 1, ease: 'Expo.easeOut'},
            alpha: [0.01, 0.1],
            // alpha: (p, k, t) => { return 1 - 2 * Math.abs(t - 0.8); }, // {start: 0.8, end: 0, ease: 'Expo.easeIn'},
            // sendToBack: true,
            quantity: 20,
            speed: 40,
            // rotate: {start: 0, end: 45},
            // angle: {min: -170, max: -190},
            angle: {min: -150, max: -210},
            // gravityY: -50,
            on: false,

            // particleBringToTop: true,
            blendMode: 'SCREEN',
        } as ParticleEmitterConfig;

        this.flameConfig = {
            frame: 'game/particles/white.png',
            delay: {min: 0, max: 200},
            lifespan: 600,
            frequency: 300,
            scale: {start: 0.05, end: 0.25, ease: 'Expo.easeOut'},
            alpha: [0, 0.08],
            // alpha: (p, k, t) => { return 1 - 2 * Math.abs(t - 0.8); }, // {start: 0.8, end: 0, ease: 'Expo.easeIn'},
            quantity: 20,
            speed: 50,
            // rotate: {start: 0, end: 45},
            angle: {min: -170, max: -190},
            // gravityY: 200,
            on: false,

            particleBringToTop: true,
            blendMode: 'ADD',
        } as ParticleEmitterConfig;


        this.particles = this.scene.add.particles('sprite');

        this.glowEmitter = this.particles.createEmitter(this.glowConfig);
        this.flameEmitter = this.particles.createEmitter(this.flameConfig);

        this.glowEmitter.startFollow(this.follow, 0, -20);
        this.flameEmitter.startFollow(this.follow, 14, -22);
    }

    fire(level) {
        // todo per level wordt de particle anders (zie oude code)
        this.glowEmitter.setFrequency(380-(level*30)).setLifespan(300+(200*level)).start();
        this.flameEmitter.setFrequency(180-(level*15)).setLifespan(500+(200*level)).start();

    }
}
