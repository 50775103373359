import {ScorePanelScene} from '../scenes/score-panel-scene';
import {GameScene} from '../scenes/game-scene';
import {Button} from './button';
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;
import TWEEN_START = Phaser.Tweens.Events.TWEEN_START;

export default class GameComplete extends Phaser.GameObjects.Container {
    private mooi: Phaser.GameObjects.Sprite | undefined;
    private opnieuw!: Button;
    private stoppen!: Button;
    public scene!: GameScene;

    constructor(scene: GameScene, x?, y?) {
        super(scene, x, y);

        this.add(scene.make.sprite({x: 0, y: -100, key: 'sprite', frame: 'game/end/text-end-goed.png'}, true));

        this.scale = 0;

        const tween = this.scene.tweens.add({targets: this, scale: 1, duration: 500, ease: 'Bounce.Out', delay: 500});
        tween.on(TWEEN_COMPLETE, () => {
            this.showComplete();
        });
        tween.on(TWEEN_START, () => {
            this.scene.audioManager.playFX('gamecomplete');
        });
    }

    showComplete(): void {
        const scoreDisplay = (this.scene.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        const x = 120;
        const y = -10;
        if (scoreDisplay.newHighScore) {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-high.png'}, true);
        } else if (scoreDisplay.newScore) {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-mooi.png'}, true);
        } else {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-leuk.png'}, true);
        }
        this.mooi.scale = 0;
        this.add(this.mooi);
        const tween = this.scene.tweens.add({targets: this.mooi, scale: 1, duration: 500, ease: 'Bounce.Out'});
        tween.on(TWEEN_START, () => {
            if (scoreDisplay.newHighScore) {
                 this.scene.audioManager.playFX('highscore');
            } else if (scoreDisplay.newScore) {
                this.scene.audioManager.playFX('highscore',.5);
            } else {
                this.scene.audioManager.playFX('highscore',.3);
            }
        });
        tween.on(TWEEN_COMPLETE, () => {
            this.showComplete2();
        });
    }

    showComplete2(): void {
        this.opnieuw = new Button(this.scene, 20, 75, () => {
            this.actionOnClickOpnieuw();
        }, 'sprite', 'game/end/btn-opnieuw-hover.png', 'game/end/btn-opnieuw.png', 'game/end/btn-opnieuw-hover.png');
        this.opnieuw.setScale(0);
        this.add(this.opnieuw);

        this.stoppen = new Button(this.scene, 300, 75, () => {
            this.actionOnClickStop();
        }, 'sprite', 'game/end/btn-stoppen-hover.png', 'game/end/btn-stoppen.png', 'game/end/btn-stoppen-hover.png');
        this.stoppen.setScale(0);
        this.add(this.stoppen);

        this.scene.tweens.add({targets: this.opnieuw, scaleX: 1, scaleY: 1, duration: 300, ease: 'Bounce.Out', delay: 1200});
        this.scene.tweens.add({targets: this.stoppen, scaleX: 1, scaleY: 1, duration: 300, ease: 'Bounce.Out', delay: 1500});
    }

    actionOnClickOpnieuw(): void {
        window.location.reload();
    }

    actionOnClickStop(): void {
        const href = window.document.getElementById('url-origin')?.getAttribute('href');
        window.location.href = href ?? '';
    }
}
