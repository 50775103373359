/**
 * Menu scene of the game.
 * This scene is only used for determining what audio loop has to be played.
 * All game objects and animation are done in score-panel-scene / dashboard
 * because then all of these graphics are bundled in one scene.
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

import TypeTuinScene from './type-tuin-scene';
import CREATE = Phaser.Scenes.Events.CREATE;

export class MenuScene extends TypeTuinScene {

    constructor() {
        super({key: 'MenuScene'});
    }

    init(): void {
        this.events.on(CREATE, () => {
            this.audioManager.playMusic();
        });
    }

    create(): void {
        super.create();
    }

    update(): void {
    }
}
