import {GameScene} from '../scenes/game-scene';
import ParticleRocket from './particleRocket';
import GameObject = Phaser.GameObjects.GameObject;
import ParticleExplode from './particleExplode';
import ParticleCorrectWord from './particleCorrectWord';
import Animation = Phaser.Types.Animations.Animation;
import AnimationFrame = Phaser.Types.Animations.AnimationFrame;
import TweenBuilderConfig = Phaser.Types.Tweens.TweenBuilderConfig;
import TWEEN_UPDATE = Phaser.Tweens.Events.TWEEN_UPDATE;
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;

class RocketFoxy extends Phaser.GameObjects.Container {
    public scene: GameScene;
    private origin: Phaser.Geom.Point;
    private gameComplete: boolean;
    private particleRocket: ParticleRocket;
    private foxy: Phaser.GameObjects.Sprite;
    private rocket: Phaser.GameObjects.Sprite;
    private particleExplode: ParticleExplode;
    private particleCorrectWord: ParticleCorrectWord;
    private lastPosition: { x: number; y: number };
    private foxyBaseFrame = 'game/vos/normal-0.png';
    private tweenOrigin!: Phaser.Tweens.Tween;

    constructor(scene: GameScene, x, y) {
        super(scene, x, y);
        this.scene = scene;

        this.origin = new Phaser.Geom.Point(x, y);
        this.gameComplete = false;
        this.particleRocket = new ParticleRocket(scene, this);
        scene.add.existing(this.particleRocket);
        this.particleRocket.fire(1);

        this.foxy = scene.make.sprite({x: 24, y: -94, key: 'sprite', frame: this.foxyBaseFrame}, true);
        this.foxy.setOrigin(0, 1);
        this.add(this.foxy);

        this.rocket = scene.make.sprite({x: 0, y: 0, key: 'sprite', frame: 'game/raket/raket-0.png'}, true);
        this.rocket.setOrigin(0, 0.5);
        this.add(this.rocket);

        this.particleExplode = new ParticleExplode(scene, this);
        scene.add.existing(this.particleExplode as unknown as GameObject);

        this.particleCorrectWord = new ParticleCorrectWord(scene, this);
        scene.add.existing(this.particleCorrectWord as unknown as GameObject);

        this.lastPosition = {x: this.x, y: this.y};
    }

    stopToOrigin() {
        if (this.tweenOrigin) {
            this.tweenOrigin.stop();
        }
    }

    shake(impact = 20) {
        this.scene.tweens.timeline({ease: 'Cubic.None'})
            .add({targets: this, x: this.x + impact / 5, duration: 20} as TweenBuilderConfig)
            .add({targets: this, x: this.x - impact / 5, duration: 20} as TweenBuilderConfig)
            .add({targets: this, x: this.x + impact / 5, duration: 20} as TweenBuilderConfig)
            .add({targets: this, x: this.x - impact / 5, duration: 20} as TweenBuilderConfig)
            .add({targets: this, x: this.x, duration: 20} as TweenBuilderConfig).play();
    }

    updateRocket(lives) {
        const index = 5 - Phaser.Math.Clamp(lives, 0, 5);
        this.rocket.setFrame('game/raket/raket-' + index + '.png', false, false);
        this.updateFoxy(lives);
    }

    updateFoxy(lives) {
        const index = Phaser.Math.Clamp(lives, 0, 5);
        this.foxyBaseFrame = 'game/vos/normal-' + (5 - index) + '.png';
        this.foxy.anims.stop();
        this.foxy.setFrame(this.foxyBaseFrame, false, false);
    }

    blink(lives, repeat = 0) {
        this.updateFoxyBehaviour('blink', lives, repeat);
    }

    fear(lives, repeat = 0) {
        this.updateFoxyBehaviour('fear', lives, repeat);
    }

    fearmore(lives, repeat = 0) {
        this.updateFoxyBehaviour('fearmore', lives, repeat);
    }

    updateFoxyBehaviour(action, lives, repeat) {
        const index = Phaser.Math.Clamp(lives, 0, 5);
        if (action == 'blink') {
            this.createAnim('blink', 'game/vos/blink-' + (5 - index) + '.png', repeat);
            this.foxy.play('blink', true);
        } else if (action == 'fear') {
            this.createAnim('fear', 'game/vos/bang-' + (5 - index) + '.png', repeat);
            this.foxy.play('fear', true);
        } else if (action == 'fearmore') {
            this.createAnim('fear', 'game/vos/bang-' + (5 - index) + '.png', repeat, 2);
            this.foxy.play('fear', true);
        }
    }

    private createAnim(key: string, atlasFrame: string, repeat = -1, playCount = 1) {
        // first remove the old anim
        this.scene.anims.remove(key);

        const frames: AnimationFrame[] = [];
        frames.push({key: 'sprite', frame: this.foxyBaseFrame} as AnimationFrame);
        for (let i = 0; i < playCount; i += 1) {
            frames.push({key: 'sprite', frame: atlasFrame} as AnimationFrame);
            frames.push({key: 'sprite', frame: this.foxyBaseFrame} as AnimationFrame);
        }
        // set the frameRate to playCount; we want to play all in the same total time
        this.scene.anims.create({key, repeat, frames, frameRate: playCount} as Animation);
    }

    hitRock(speed, lives) {
        this.updateRocket(lives - 1);
        this.scene.audioManager.playFX('wordwrong');
        this.shake();
        this.particleExplode.fire(speed);
        this.moveToOrigin(speed);
    }

    hitTimeup(speed, lives) {
        this.updateRocket(lives - 1);
        this.scene.audioManager.playFX('launch');
        this.shake(40);
        this.particleExplode.fire(speed);
        this.moveToOrigin(speed);
    }

    hitBonus(speed, lives) {
        this.updateRocket(lives);
        this.scene.audioManager.playFX('wordok', .8);
        this.particleCorrectWord.fire();
        this.moveToOrigin(speed);
    }

    hitLevelup(speed, lives) {
        this.updateRocket(lives);
        this.scene.audioManager.playFX('highscore');
        this.particleCorrectWord.fire();
        this.moveToOrigin(speed);
        this.particleRocket.fire(speed);
    }

    moveToOrigin(speed) {
        this.tweenOrigin = this.scene.tweens.add({
            targets: this,
            x: this.origin.x,
            y: this.origin.y,
            duration: 1500 / speed,
            ease: 'linear',
            delay: 500,
        });
        this.tweenOrigin.on(TWEEN_UPDATE, () => {
            this.setOrientation()
        });
        this.tweenOrigin.on(TWEEN_COMPLETE, () => {
            this.resetOrientation();
        });
    }

    resetOrientation() {
        this.rotation = 0;
        // game complete: happy wave animation
        if (this.gameComplete) {
            this.wave();
        }
    }

    setOrientation() {
        this.rotation = Phaser.Math.Angle.BetweenPoints(this.lastPosition, this) / 50;
        this.lastPosition.x = this.x;
        this.lastPosition.y = this.y;
    }

    wiggle() {
        this.rotation = -.02;
        this.scene.tweens.add({
            targets: this,
            rotation: {from: .05, to: this.rotation},
            duration: 1800,
            ease: 'Linear.Out',
            repeat: -1,
            yoyo: true,
        });
    }

    wave() {
        this.tweenOrigin = this.scene.tweens.add({
            targets: this,
            y: this.origin.y + 100,
            duration: 500,
            ease: 'Linear',
            repeat: -1,
            yoyo: true,
        });
        this.tweenOrigin.on(TWEEN_UPDATE, () => {
            this.setOrientation();
        });
    }


}

export default RocketFoxy;
